@import url('https://rsms.me/inter/inter.css');
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
  cursor: none !important;
}

*::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  background: rgb(255, 240, 246);
  background: radial-gradient(circle, rgba(255, 240, 246, 1) 0%, rgba(249, 206, 224, 1) 35%, rgba(255, 163, 201, 1) 100%);
}

.content-container {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0 !important;
  padding: 0 !important;
}

.content-embed {
  position: relative;
  background: white;
  border-radius: 37.5px;
  width: 375px;
  height: 825px;
  overflow: scroll;
}

canvas {
  position: absolute;
  z-index: 1;
}

.glowhover {
  /* width: 220px;
  height: 50px; */
  /* border: none;
  outline: none; */
  background: #111;
  cursor: pointer;
  position: relative;
  z-index: 0;
  padding: 1rem;
  border-radius: 1.5rem;
  backdrop-filter: blur(2px);
  background-color: #111827;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
}

.glowhover:before {
    content: '';
    background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
    position: absolute;
    top: -2px;
    left: -2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing 20s linear infinite;
    opacity: 0;
    transition: opacity .3s ease-in-out;
    border-radius: 1.5rem;
}

.glowhover:active {
    color: #000
}

.glowhover:active:after {
    background: transparent;
}

.glowhover:hover:before {
    opacity: 1;
}

.glowhover:after {
    z-index: -1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: #111827;
    left: 0;
    top: 0;
    border-radius: 1.5rem;
}

@keyframes glowing {
    0% {
        background-position: 0 0;
    }

    50% {
        background-position: 400% 0;
    }

    100% {
        background-position: 0 0;
    }
}

.singleglow {
  /* width: 220px;
  height: 50px; */
  /* border: none;
  outline: none; */
  background: #111;
  cursor: pointer;
  position: relative;
  z-index: 0;
  padding: 1rem;
  border-radius: 9999px;
  backdrop-filter: blur(2px);
  background-color: #111827;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
}

.singleglow:before {
    content: '';
    background: linear-gradient(45deg, #002bff, #fff700);
    position: absolute;
    top: -2px;
    left: -2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: singlegg 20s linear infinite;
    opacity: 0;
    transition: opacity .3s ease-in-out;
    border-radius: 9999px;
}

.singleglow:active {
    color: #000
}

.singleglow:active:after {
    background: transparent;
}

.singleglow:hover:before {
    opacity: 1;
}

.singleglow:after {
    z-index: -1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: #111827;
    left: 0;
    top: 0;
    border-radius: 9999px;
}

@keyframes singlegg {
    0% {
        background-position: 0 0;
    }

    50% {
        background-position: 400% 0;
    }

    100% {
        background-position: 0 0;
    }
}